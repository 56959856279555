export async function getErrorMsg(error: any): Promise<string> {
	if (!error.response && !error.message) {
		return "Unexpected error";
	}

	if (!error.response) {
		return error.message;
	}

	const response = await error.response.json();

	if (response.message) {
		return response.message;
	}

	return "Unknown error";
}
