import { JSXElement, Show } from "solid-js";
import { BiRegularCategoryAlt } from "solid-icons/bi";
import { A } from "@solidjs/router";
import { ProductCategoryData } from "../../../interfaces/dto";
import { CategoriesDropdown } from "./CategoriesDropdown";

export interface CategoriesDropdownItemProps {
	name: string;
	icon?: JSXElement;
	href?: string;
	onClick?: (e: MouseEvent) => void;
	hasSeparator: boolean;
	children?: ProductCategoryData[];
}

export function CategoriesDropdownItem(props: CategoriesDropdownItemProps) {
	return (
		<li
			class={`${
				props.hasSeparator ? "border-b" : ""
			} group flex w-full flex-wrap items-center justify-between px-4 hover:text-black`}
		>
			<div class="flex items-center">
				<span class="mr-2 ">{props.icon ?? <BiRegularCategoryAlt />}</span>

				<A href={props.href ?? ""} class="block py-4">
					{props.name}
				</A>
			</div>

			<Show when={props.children && props.children.length > 0}>
				<CategoriesDropdown
					title={props.name}
					productCategories={props.children}
				/>
			</Show>
		</li>
	);
}
