import ky from "ky";
import { AppConfig } from "../config";
import { BaseResponse } from "../interfaces/response";
import { getErrorMsg } from "../utils/http";
import { CartItemData } from "../interfaces/dto";

const baseApiUrl = AppConfig.baseApiUrlV1;

export interface GetCartItemsResponse extends BaseResponse {
	data: CartItemData[];
}

export interface AddToCartResult {
	productId: number;
	quantity: number;
	itemKey: string | false;
}

export interface AddProductToCartResponse extends BaseResponse {
	data?: AddToCartResult;
	cart?: CartItemData[];
}

export interface AddToCartProps {
	productId: number;
	quantity: number;
	variationId?: number;
	variations?: object;
	cartItemData?: object;
}

export async function addProductToCart(
	props: AddToCartProps
): Promise<AddProductToCartResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky
			.post(apiUrl, {
				json: {
					single_mode: true,
					product_id: props.productId,
					quantity: props.quantity,
					variation_id: props.variationId,
					variations: props.variations,
					cartItemData: props.cartItemData,
				},
			})
			.json<AddProductToCartResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface AddProductListToCartResponse extends BaseResponse {
	data?: AddToCartResult[];
	cart?: CartItemData[];
}

export async function addProductListToCart(
	props: AddToCartProps[]
): Promise<AddProductListToCartResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	const items: AddToCartProps[] = [];

	props.forEach((item) => {
		items.push({
			productId: item.productId,
			quantity: item.quantity,
			variationId: item.variationId,
			variations: item.variations,
			cartItemData: item.cartItemData,
		});
	});

	try {
		const response = await ky
			.post(apiUrl, {
				json: {
					items: items,
				},
			})
			.json<AddProductListToCartResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface UpdateCartResult {
	cartItemKey: string;
	quantity: number;
	cart?: CartItemData[];
}

export interface UpdateCartItemResponse extends BaseResponse {
	data?: UpdateCartResult;
	cart?: CartItemData[];
}

export async function updateCartItem(
	cartItemKey: string,
	quantity: number
): Promise<UpdateCartItemResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky
			.put(apiUrl, {
				json: {
					single_mode: true,
					cart_item_key: cartItemKey,
					quantity: quantity,
				},
			})
			.json<UpdateCartItemResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface UpdateCartItemListResponse extends BaseResponse {
	data?: UpdateCartResult[];
	cart?: CartItemData[];
}

export async function updateCartItemList(
	cartItemList: { cartItemKey: string; quantity: number }[]
): Promise<UpdateCartItemListResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky
			.put(apiUrl, {
				json: {
					cartItemList,
				},
			})
			.json<UpdateCartItemListResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface RemoveCartItemResult {
	success: boolean;
	itemKey: string | false;
}

export interface RemoveCartItemResponse extends BaseResponse {
	data?: RemoveCartItemResult;
	cart?: CartItemData[];
}

export async function removeCartItem(
	cartItemKey: string
): Promise<RemoveCartItemResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky
			.delete(apiUrl, {
				json: {
					single_mode: true,
					cart_item_key: cartItemKey,
				},
			})
			.json<RemoveCartItemResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface RemoveCartItemListResponse extends BaseResponse {
	data?: RemoveCartItemResult[];
	cart?: CartItemData[];
}

export async function removeCartItemList(
	cartItemList: string[]
): Promise<RemoveCartItemListResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky
			.delete(apiUrl, {
				json: {
					cartItemList,
				},
			})
			.json<RemoveCartItemListResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
			cart: response.cart,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
		};
	}
}

export interface GetCartResponse extends BaseResponse {
	data: CartItemData[];
}

export async function getCart(): Promise<GetCartResponse> {
	const apiUrl = `${baseApiUrl}/cart`;

	try {
		const response = await ky.get(apiUrl).json<GetCartResponse>();

		return {
			success: true,
			message: response.message,
			data: response.data,
		};
	} catch (e: unknown) {
		return {
			success: false,
			message: await getErrorMsg(e),
			data: [],
		};
	}
}
