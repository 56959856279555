import { VsClose } from "solid-icons/vs";

export interface MobileMenuHeaderProps {
	onCloseButtonClick?: (e: MouseEvent) => void;
}

export default function MobileMenuHeader(props: MobileMenuHeaderProps) {
	return (
		<>
			<header class="mobile-menu-header mb-2 px-5 pb-2 pt-5">
				<div class="relative flex w-full items-center">
					<h4 class="flex-grow text-lg font-semibold">Menu</h4>
					<button
						class="mobile-menu-close-button flex h-7 w-7 items-center justify-center rounded-full bg-black text-xl text-white"
						onClick={props.onCloseButtonClick}
					>
						<VsClose />
					</button>
				</div>

				{/* <MobileMenuSearch class="mt-2" /> */}
			</header>
		</>
	);
}
