import CartCountBadge from "./CartCountBadge";
import { Show } from "solid-js";
import cart from "../../stores/cart";
import { getCart } from "../../services/cart-service";
import { FiShoppingCart } from "solid-icons/fi";

export interface CartIndicatorProps {}

export default function CartIndicator(props: CartIndicatorProps) {
	const [items, setItems] = cart;

	async function fetchData() {
		const response = await getCart();

		if (response.success) {
			setItems(response.data);
		}
	}

	fetchData();

	function countItemsQuantities() {
		let count = 0;

		items().forEach((item) => {
			count += item.quantity;
		});

		return count;
	}

	return (
		<a
			class="group relative inline-block rounded-full p-2 text-black transition duration-300 hover:bg-gray-950 hover:text-white"
			href="/cart/"
			title="Keranjang belanja"
		>
			<FiShoppingCart class="" />

			<Show when={items().length > 0}>
				<CartCountBadge count={countItemsQuantities()} />
			</Show>
		</a>
	);
}
