import { BsTelephone, BsWhatsapp } from "solid-icons/bs";
import { FiFacebook, FiInstagram } from "solid-icons/fi";

export interface SimpleFooterProps {
	hasBg?: boolean;
	class?: string;
}

export default function SimpleFooter(props: SimpleFooterProps) {
	return (
		<footer
			class={`site-footer mb-14 md:mb-0 ${
				props.hasBg ? "bg-black pt-10 text-cyan-50" : "pt-10"
			} ${props.class ?? ""}`}
		>
			<div class="container">
				<div class="text-center">
					<address>
						Jalan Jembatan Merah No: 116A, Soropadan, Condongcatur, Depok,
						Sleman, DI Yogyakarta, Indonesia – 55283
					</address>

					<div class="footer-social-icons text-md mt-3 flex justify-center gap-5 md:mt-2">
						<a
							class="hover:text-companycolor"
							href="https://www.facebook.com/youngscomputer/"
						>
							<FiFacebook />
						</a>
						<a
							class="hover:text-companycolor"
							href="https://www.instagram.com/youngscom"
						>
							<FiInstagram />
						</a>
						<a
							class="hover:text-companycolor"
							href="https://wa.me/628112644226?text=Halo%20Youngs%20Computer,%20"
						>
							<BsWhatsapp />
						</a>
						<a
							class="inline-flex items-center hover:text-companycolor"
							href="tel:+6283867309810"
						>
							<BsTelephone />
						</a>
					</div>

					<div
						class={`mt-10 block border-t ${
							props.hasBg
								? "border-darkBorderColor text-cyan-100"
								: "border-lightbordercolor text-slate-600"
						} py-6 text-center `}
					>
						<small class="text-sm">
							© 2022. All rights reserved by{" "}
							<a href="https://youngs.id/">Youngs Computer</a>.
						</small>
					</div>
				</div>
			</div>
		</footer>
	);
}
