import { A } from "@solidjs/router";
import {
	BiRegularCategoryAlt,
	BiRegularSearch,
	BiRegularUserCircle,
} from "solid-icons/bi";
import { FiHome } from "solid-icons/fi";

export interface BottomNavbarProps {
	class?: string;
}

export default function BottomNavbar(props: BottomNavbarProps) {
	return (
		<section
			class={`bottom-navbar ${
				props.class ?? ""
			} z-88888 bg-darkBlueColor fixed inset-x-0 bottom-0 block w-screen overflow-x-scroll px-4 pb-1 pt-2 text-cyan-100 shadow sm:px-8 md:hidden`}
		>
			<div class="bottom-navbar-tabs flex w-full justify-between">
				<A
					href="/"
					class="block text-center hover:text-cyan-50 focus:text-cyan-50"
				>
					<FiHome class="mx-auto" />
					<span class="bottom-navbar-tab tab-home inline-block text-xs">
						Home
					</span>
				</A>
				<A
					href="/components/"
					class="group block text-center hover:text-cyan-50 focus:text-cyan-50"
				>
					<BiRegularSearch class="mx-auto group-focus:fill-orange-300" />
					<span class="bottom-navbar-tab tab-categories inline-block text-xs">
						Komponen
					</span>
				</A>
				<A
					href="/rakit/"
					class="block text-center hover:text-cyan-50 focus:text-cyan-50"
				>
					<BiRegularCategoryAlt class="mx-auto" />
					<span class="bottom-navbar-tab tab-categories inline-block text-xs">
						Rakit PC
					</span>
				</A>
				<a
					href="/my-account/"
					class="group block text-center hover:text-cyan-50 focus:text-cyan-50"
				>
					<BiRegularUserCircle class="mx-auto group-focus:fill-orange-300" />
					<span class="bottom-navbar-tab tab-account inline-block text-xs">
						Account
					</span>
				</a>
			</div>
		</section>
	);
}
