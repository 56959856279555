export interface CartCountBadgeProps {
	count?: number;
}

export default function CartCountBadge(props: CartCountBadgeProps) {
	return (
		<>
			<div class="absolute -right-1 -top-0 inline-flex h-[1.3rem] min-w-[1.3rem] items-center justify-center rounded-full border-white bg-secondarycolor p-1 text-[0.6rem] font-medium leading-3 text-white group-hover:border dark:border-gray-900">
				{props.count ?? 0}
			</div>
		</>
	);
}
