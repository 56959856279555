import { OcThreebars2 } from "solid-icons/oc";
import Logo from "./Logo";
import { createSignal, Show } from "solid-js";
import MobileMenu from "./mobile-menu/MobileMenu";
import CartIndicator from "./cart/CartIndicator";
import { A, useLocation } from "@solidjs/router";

export default function NavBar() {
	const [mobileMenuOpen, setMobileMenuOpen] = createSignal<boolean>(false);

	function toggleMobileMenu(e: MouseEvent) {
		setMobileMenuOpen(!mobileMenuOpen());
	}

	const location = useLocation();

	function getCurrentPath() {
		const untrailingSlashed = location.pathname.replace(/^\/|\/$/g, "");

		return untrailingSlashed;
	}

	return (
		<>
			<MobileMenu
				isOpen={mobileMenuOpen()}
				onCloseButtonClick={toggleMobileMenu}
				onToggle={toggleMobileMenu}
			/>

			<section
				class={`fixed left-0 top-0 z-[99999] w-full bg-lightBlueColor py-2.5 md:relative md:z-[1] md:py-4`}
			>
				<div class="container">
					<div class="mx-auto flex w-full items-center justify-between font-semibold lg:w-3/4 xl:w-2/3">
						<Show when={getCurrentPath() !== "components"} fallback="&nbsp;">
							<button
								type="button"
								class="mobile-menu-open-button flex items-center text-xl focus:bg-companycolor md:hidden"
								onClick={toggleMobileMenu}
							>
								<span class="mr-2 text-xl">
									<OcThreebars2 />
								</span>
							</button>
						</Show>

						<div class="navbar-start hidden items-center justify-end md:flex">
							<Logo
								class={`h-10 md:h-12 lg:h-14 ${getCurrentPath() === "components" ? "hidden md:inline-flex" : ""}`}
							/>
						</div>

						<div class="navbar-center flex items-center justify-center">
							<Logo
								class={`h-9 ${getCurrentPath() === "components" ? "hidden" : "md:hidden"}`}
							/>

							<div class="hidden md:block">
								<A
									class="px-5 transition-all hover:text-cyan-50"
									href="/product-category/custom-pc/"
								>
									PAKET PC
								</A>
								<A
									class="px-5 transition-all hover:text-cyan-50"
									href="/rakit/"
								>
									RAKIT PC
								</A>
								<A
									class="px-5 transition-all hover:text-cyan-50"
									href="/components/"
								>
									KOMPONEN
								</A>
							</div>
						</div>

						<div class="navbar-end flex items-center justify-end">
							<a
								class="account-button hidden rounded-full bg-gray-950 px-5 py-2 text-sm font-normal text-white transition-all hover:bg-black hover:text-cyan-300 md:inline-block"
								href="/my-account/"
							>
								Account
							</a>

							<div class="inline-flex pl-5 text-xl">
								<CartIndicator />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
