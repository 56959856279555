import { A } from "@solidjs/router";

export interface LogoProps {
	class?: string;
}

import LogoUrl from "../assets/logo-fit.png";

export default function Logo(props: LogoProps) {
	return (
		<A
			href="/"
			class={`relative inline-flex items-center ${props.class ?? ""}`}
		>
			<img class="max-h-full max-w-full" src={LogoUrl} alt="Logo" />
		</A>
	);
}
