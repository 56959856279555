import { For, Show, createSignal } from "solid-js";
import { FaSolidAngleDown, FaSolidAngleUp } from "solid-icons/fa";
import { ProductCategoryData } from "../../../interfaces/dto";
import { CategoriesDropdownItem } from "./CategoriesDropdownItem";

export interface CategoriesDropdownProps {
	isTopLevel?: boolean;
	title: string;
	productCategories?: ProductCategoryData[];
}

export function CategoriesDropdown(props: CategoriesDropdownProps) {
	const [dropdownOpen, setDropdownOpen] = createSignal<boolean>(false);

	function toggleDropdown() {
		setDropdownOpen(!dropdownOpen());
	}

	return (
		<Show when={props.productCategories && props.productCategories.length > 0}>
			<button
				class={
					(props.isTopLevel ? "flex w-full text-lg" : "inline-flex") +
					" items-center justify-between font-semibold active:text-companycolor"
				}
				onClick={toggleDropdown}
			>
				<Show when={props.isTopLevel}>
					<span class="">{props.title}</span>
				</Show>

				<span class="relative ml-5 inline-block pt-1">
					<Show when={dropdownOpen()} fallback={<FaSolidAngleDown />}>
						<FaSolidAngleUp />
					</Show>
				</span>
			</button>

			<Show when={dropdownOpen()}>
				<ul class="relative w-full">
					<For each={props.productCategories!}>
						{(category, i) => (
							<CategoriesDropdownItem
								name={category.name}
								href={`/product-category/${category.slug}`}
								hasSeparator={i() < props.productCategories!.length - 1}
							/>
						)}
					</For>
				</ul>
			</Show>
		</Show>
	);
}
