import { Show, onCleanup, onMount } from "solid-js";
import MobileMenuHeader from "./MobileMenuHeader";
import MobileMenuItem from "./MobileMenuItem";
import SimpleBar from "simplebar";
import { ProductCategoryData } from "../../interfaces/dto";
import { CategoriesDropdown } from "./dropdown/CategoriesDropdown";

export interface MobileMenuProps {
	class?: string;
	isOpen: boolean;
	onCloseButtonClick: (e: MouseEvent) => void;
	onToggle: (e: MouseEvent) => void;
	productCategories?: ProductCategoryData[];
}

export default function MobileMenu(props: MobileMenuProps) {
	let scrollbarRef: HTMLDivElement | undefined;
	let scrollbar: SimpleBar | undefined;

	onMount(() => {
		scrollbar = new SimpleBar(scrollbarRef!);
	});

	onCleanup(() => {
		scrollbar?.unMount();
	});

	return (
		<div
			class={`mobile-menu-overlay ${
				props.isOpen
					? "visible z-[999999] bg-opacity-90"
					: "invisible z-0 opacity-0"
			} fixed left-0 top-0 h-screen w-screen bg-black transition-all duration-[400ms] md:invisible md:z-0 md:opacity-0`}
			onClick={(e: MouseEvent) => {
				const target = e.target;

				if (target instanceof HTMLDivElement) {
					if (target.classList.contains("mobile-menu-overlay")) {
						props.onCloseButtonClick(e);
					}
				}
			}}
		>
			<div
				class={`mobile-menu ${
					props.isOpen ? "left-0" : "left-[-200%]"
				} relative z-10 h-full w-10/12 bg-neutral-900 text-cyan-100 transition-all duration-[400ms] sm:w-7/12 md:w-6/12`}
				ref={scrollbarRef}
			>
				<MobileMenuHeader onCloseButtonClick={props.onCloseButtonClick} />

				<div class="mobile-menu-body relative w-full px-5 pb-5">
					<ul class="relative mt-5">
						<MobileMenuItem
							text="Home"
							href="/"
							onClick={props.onCloseButtonClick}
						/>
						<MobileMenuItem
							text="Komponen"
							href="/components/"
							onClick={props.onCloseButtonClick}
						/>
						<MobileMenuItem
							text="Rakit PC"
							href="/rakit/"
							onClick={props.onCloseButtonClick}
						/>
						<MobileMenuItem
							text="Keranjang belanja"
							href="/cart/"
							onClick={props.onCloseButtonClick}
						/>
						<MobileMenuItem
							text="Akun"
							href="/my-account/"
							onClick={props.onCloseButtonClick}
						/>
					</ul>

					<div class="mt-2">
						<Show when={props.productCategories}>
							<CategoriesDropdown
								isTopLevel={true}
								title="Product categories"
								productCategories={props.productCategories}
							/>
						</Show>
					</div>
				</div>
			</div>
		</div>
	);
}
